export const SituacaoAssistencia = {
  RASCUNHO: "Rascunho",
  NOVA: "Nova",
  EM_ANALISE: "Em Análise",
  AGUARDANDO_ENVIO: "Aguardando Envio",
  AGUARDANDO_RETORNO: "Aguardando Retorno",
  AGUARDANDO_RESPOSTA: "Aguardando Resposta",
  REPROVADA: "Reprovada",
  CANCELADA: "Cancelada",
  RETORNADA: "Retornada",
  ENVIADA: "Enviada",
  APROVADA: "Aprovada",
  FINALIZADA: "Finalizada"
}

export const SituacaoVisitaTecnica = {
  REPROVADA: "Reprovada",
  APROVADA: "Aprovada",
  CANCELADA: "Cancelada",
  REALIZADA: "Realizada"
}

export function getStyleStatus(situacao, width) {

  const _width = width ?? "10px";

  if (situacao === SituacaoAssistencia.RASCUNHO) {
    return { background: "#f7c16a", color: "black", width: _width };
  } else if (situacao === SituacaoAssistencia.NOVA) {
    return { background: "#008080", color: "white", width: _width };
  } else if (situacao === SituacaoAssistencia.EM_ANALISE) {
    return { background: "#754c75", color: "white", width: _width };
  } else if (situacao === SituacaoAssistencia.AGUARDANDO_ENVIO) {
    return { background: "#aa1430", color: "white", width: _width };
  } else if (situacao === SituacaoAssistencia.AGUARDANDO_RETORNO) {
    return { background: "#DA7B00", color: "white", width: _width };
  } else if (situacao === SituacaoAssistencia.AGUARDANDO_RESPOSTA) {
    return { background: "#448fb5", color: "white", width: _width };
  } else if (situacao === SituacaoAssistencia.REPROVADA) {
    return { background: "#4F0202", color: "white", width: _width };
  } else if (situacao === SituacaoAssistencia.CANCELADA) {
    return { background: "#593c31", color: "white", width: _width };
  } else if (situacao === SituacaoAssistencia.RETORNADA) {
    return { background: "#BAAA5D", color: "white", width: _width };
  } else if (situacao === SituacaoAssistencia.ENVIADA) {
    return { background: "#468A96", color: "white", width: _width };
  } else if (situacao === SituacaoAssistencia.APROVADA) {
    return { background: "#23b26d", color: "white", width: _width };
  } else if (situacao === SituacaoAssistencia.FINALIZADA) {
    return { background: "#3364BD", color: "white", width: _width };
  }
  return null;
}

export function getBackGroundSituacaoVisita(situacao) {

  if (situacao === SituacaoAssistencia.REPROVADA) {
    return "#ec0707";
  } else if (situacao === SituacaoAssistencia.APROVADA) {
    return "#a9d8c1";
  } else if (situacao === SituacaoAssistencia.CANCELADA) {
    return "#ec0707";
  } else if (situacao === SituacaoAssistencia.REALIZADA) {
    return "#16db5b";
  }
  return null;
}

export function getColorSituacaoVisita(situacao) {

  if (situacao === SituacaoAssistencia.REPROVADA) {
    return "white";
  } else if (situacao === SituacaoAssistencia.APROVADA) {
    return "black";
  } else if (situacao === SituacaoAssistencia.CANCELADA) {
    return "white";
  } else if (situacao === SituacaoAssistencia.REALIZADA) {
    return "black";
  }
  return null;
}

export function getBackGroundStatus(situacao) {

  if (situacao === SituacaoAssistencia.RASCUNHO) {
    return "#FFFFB0";
  } else if (situacao === SituacaoAssistencia.NOVA) {
    return "#008080";
  } else if (situacao === SituacaoAssistencia.EM_ANALISE) {
    return "#754c75";
  } else if (situacao === SituacaoAssistencia.AGUARDANDO_ENVIO) {
    return "#aa1430";
  } else if (situacao === SituacaoAssistencia.AGUARDANDO_RETORNO) {
    return "#DA7B00";
  } else if (situacao === SituacaoAssistencia.AGUARDANDO_RESPOSTA) {
    return "#448fb5";
  } else if (situacao === SituacaoAssistencia.REPROVADA) {
    return "#4F0202";
  } else if (situacao === SituacaoAssistencia.CANCELADA) {
    return "#6B4B3E";
  } else if (situacao === SituacaoAssistencia.RETORNADA) {
    return "#BAAA5D";
  } else if (situacao === SituacaoAssistencia.ENVIADA) {
    return "#468A96";
  } else if (situacao === SituacaoAssistencia.APROVADA) {
    return "#23b26d";
  } else if (situacao === SituacaoAssistencia.FINALIZADA) {
    return "#3364BD";
  }
  return null;
}


export function getTextColorStatus(situacao) {

  if (situacao === SituacaoAssistencia.RASCUNHO) {
    return "black";
  }
  return "white";
}

export function getStyleSituacao(Situacao) {
  return {
    background: getBackGroundStatus(Situacao),
    color: getTextColorStatus(Situacao)
  };
}

export function getStyleSituacaoVisitaTecnica(situacaoVisitaTecnica) {
  return {
    background: getBackGroundSituacaoVisita(situacaoVisitaTecnica),
    color: getColorSituacaoVisita(situacaoVisitaTecnica),
    width: "160px",
    textAlign: "center",
    border: "1px solid #afafaf",
  };

}

export function getStyle(Situacao) {

  if (Situacao === SituacaoAssistencia.RASCUNHO) {
    return {
      background: getBackGroundStatus(Situacao),
      color: getTextColorStatus(Situacao),
      width: "160px",
      textAlign: "center",
      border: "1px solid #afafaf",

    };
  } else if (Situacao === SituacaoAssistencia.NOVA) {
    return {
      background: getBackGroundStatus(Situacao),
      color: getTextColorStatus(Situacao),
      width: "160px",
      border: "1px solid #afafaf",
    };
  } else if (Situacao === SituacaoAssistencia.EM_ANALISE) {
    return {
      background: getBackGroundStatus(Situacao),
      color: getTextColorStatus(Situacao),
      width: "160px",
      border: "1px solid #afafaf",

    };
  } else if (Situacao === SituacaoAssistencia.AGUARDANDO_ENVIO) {
    return {
      background: getBackGroundStatus(Situacao),
      color: getTextColorStatus(Situacao),
      fontSize: "11px",
      padding: 4,
      width: "160px",
      height: "38px",
      border: "1px solid #afafaf",

    };
  }
  else if (Situacao === SituacaoAssistencia.AGUARDANDO_RETORNO) {
    return {
      background: getBackGroundStatus(Situacao),
      color: getTextColorStatus(Situacao),
      fontSize: "11px",
      padding: 4,

      height: "38px",
      border: "1px solid #afafaf",

    };
  }
  else if (Situacao === SituacaoAssistencia.REPROVADA) {
    return {
      background: getBackGroundStatus(Situacao),
      color: getTextColorStatus(Situacao),
      fontSize: "14px",
      padding: 8,
      width: "160px",
      border: "1px solid #afafaf",
    };
  }
  else if (Situacao === SituacaoAssistencia.CANCELADA) {
    return {
      background: getBackGroundStatus(Situacao),
      color: getTextColorStatus(Situacao),
      fontSize: "14px",
      padding: 8,
      width: "160px",
      border: "1px solid #afafaf",
    };
  }
  else if (Situacao === SituacaoAssistencia.ENVIADA) {
    return {
      background: getBackGroundStatus(Situacao),
      color: getTextColorStatus(Situacao),
      fontSize: "14px",
      padding: 8,
      width: "160px",
      border: "1px solid #afafaf",
    };
  }

  else if (Situacao === SituacaoAssistencia.RETORNADA) {
    return {
      background: getBackGroundStatus(Situacao),
      color: getTextColorStatus(Situacao),
      fontSize: "14px",
      padding: 8,
      width: "160px",
      border: "1px solid #afafaf",
    };
  }

  else if (Situacao === SituacaoAssistencia.APROVADA) {
    return {
      background: getBackGroundStatus(Situacao),
      color: getTextColorStatus(Situacao),
      fontSize: "14px",
      padding: 8,
      width: "160px",
      border: "1px solid #afafaf",
    };

  }
  else if (Situacao === SituacaoAssistencia.FINALIZADA) {
    return {
      background: getBackGroundStatus(Situacao),
      color: getTextColorStatus(Situacao),
      width: "138px",
      border: "1px solid #afafaf",
    };
  }

  return null;
}
