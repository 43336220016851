import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class TabelaPrecoService extends ServiceBase {

  getAll(filter) {
    return http.get("/tabelaprecos" + this.getFilter(filter));
  }

  getAllList(filter, cliente, tipofrete) {
    const filtroadicional = `cliente=${cliente}&tipofrete=${Number(tipofrete ?? 0)}`;
    return http.get(`/tabelaprecos/list${this.getFilter(filter)}&${filtroadicional}`);
  }

  get(id, cliente, tipofrete) {
    const filtroadicional = `?cliente=${cliente}&tipofrete=${Number(tipofrete ?? 0)}`;
    return http.get(`/tabelaprecos/${id}${filtroadicional}`);
  }

}

export default new TabelaPrecoService();