import React, { useState } from 'react';
import TabelaPrecoService from '../../services/service.tabelapreco';
import NewSearchBarControlFormik from './search-Bar.Control.Formik';

const SearchListTabelaPrecos = (props) => {
  const [tabelaprecos, setTabelaPrecos] = useState(null);
  const [pages, setPages] = useState(null);

  const columns = [
    {
      field: 'Id',
      headerName: 'Codigo',
      width: 90,
      key: true
    },
    {
      field: 'Nome',
      headerName: 'Nome',
      width: 150,
    }
  ];

  const columnsFilter = [
    {
      field: 'Id',
      headerName: 'Codigo',
      tipo: 'number'
    },
    {
      field: 'Nome',
      headerName: 'Nome',
      tipo: 'text'
    }
  ];

  const onSearchTitle = (filter) => {
    TabelaPrecoService.getAllList(filter, props.cliente ? props.cliente : 0, props.tipofrete)
      .then(response => {
        setTabelaPrecos(response.data.value);
        setPages(response.data.pages);
      })
      .catch(e => {
        props.onError && props.onError(e);
      });
  };

  const onGetItem = (id, onLoadError) => {
    id && TabelaPrecoService.get(id, props.cliente ? props.cliente : 0, props.tipofrete)
      .then(response => {
        if (response.response?.data.error) {
          console.error(response.response?.data.error);
        } else {
          response.data && onItemSelected(response.data);
        }
      })
      .catch(e => {
        props.onError && props.onError(e);
        onLoadError && onLoadError(e);
      });
  };

  const onItemSelected = (item) => {
    props.onItemSelected(item);
  };

  return (
    <>
      <NewSearchBarControlFormik
        {...props}
        pages={pages}
        onSearchTitle={onSearchTitle}
        onGetItem={onGetItem}
        onItemSelected={onItemSelected}
        data={tabelaprecos}
        columns={columns}
        columnsFilter={columnsFilter}
        storagename={"TabelaPrecos"}
      />
    </>
  );
}

export default SearchListTabelaPrecos;