import React, { useContext, useEffect } from "react";
import {
  Box,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  Tab,
  Tabs,
  Grid,
  Drawer,
} from "@mui/material";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { FaArrowAltCircleLeft } from "react-icons/fa";
import AssistenciaValidacoes from "./assistencias-validacoes.jsx";

import { FiMenu } from "react-icons/fi";
import AssistenciaListaAcoes from "./assistencia-lista-acoes.jsx";
import AssistenciaTabs from "./assistencia-tabs.jsx";
import { AssistenciasContext } from "../../contexts/assistencias-context.js";
import { useFormikContext } from "formik";

export default function ViewAssistencias() {
  //const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const screenWidth = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    constantes,
    somenteLeitura,
    loading,
    menu,
    abrirMenu,
    fecharMenu,
    tabValue,
    setTab,
    assistenciaDuplicada,
    assistenciaEdicao,
    nextStepCadastroAssistencia,
    nextStepEditarAssistencia,
    nextStepDuplicarAssistencia,
    nextStepVisualizarAssistencia
  } = useContext(AssistenciasContext);

  const {
    setFieldValue,
    values
  } = useFormikContext();

  useEffect(() => {
    assistenciaEdicao && setFieldValue("Assistencia", assistenciaEdicao);
  }, [assistenciaEdicao])

  useEffect(() => {
    assistenciaDuplicada && setFieldValue("Assistencia", assistenciaDuplicada);
  }, [assistenciaDuplicada])

  function selecionarTab(event, newValue) {
    setTab(newValue);
    fecharMenu();
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={1}
      >
        <Box
          display="flex"
          alignItems="center"
          p={screenWidth ? 1 : 3}
          gap={screenWidth ? 1 : 3}
        >
          {!screenWidth && (
            <FaArrowAltCircleLeft
              size={30}
              color="#0d9b86"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/assistencias");

              }}
            />)}
          <Typography
            variant={screenWidth ? "h6" : "h5"}
            color={"grey"}
            component={screenWidth ? "h6" : "h5"}
          >
            {nextStepCadastroAssistencia && !nextStepVisualizarAssistencia && constantes.NovaAssistencia}
            {nextStepEditarAssistencia && `${somenteLeitura ? constantes.VisualizarAssistencia : constantes.EditarAssistencia} ${values.Assistencia ? values.Assistencia?.Id : ""}`}
            {nextStepDuplicarAssistencia && constantes.DuplicarAssistencia}
            {nextStepVisualizarAssistencia && constantes.VisualizarAssistencia}
          </Typography>
        </Box>
        {screenWidth && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"center"}
          >
            <Box mb={0.5} mr={1}>
              <FiMenu onClick={abrirMenu} />
            </Box>
            <Drawer
              variant="temporary"
              anchor="top"
              open={menu}
              onClose={fecharMenu}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Tabs
                    value={tabValue}
                    onChange={selecionarTab}
                    aria-label="Tabs"
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    orientation="vertical"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    textColor={"#007767"}
                    TabIndicatorProps={{
                      style: { backgroundColor: "#007767" },
                    }}
                  >
                    <Tab label={constantes.Principal} sx={{ color: "#007767" }} />
                    {values.Assistencia?.Cliente && <Tab label={constantes.Problema} sx={{ color: "#007767" }} />}
                    {values.Assistencia?.Cliente && values.Assistencia?.Id ? <Tab label={constantes.Anexos} sx={{ color: "#007767" }} /> : assistenciaDuplicada && <Tab label={constantes.Anexos} sx={{ color: "#007767" }} />}
                    {values.Assistencia?.Cliente && values.Assistencia?.Id ? <Tab label={constantes.Comentarios} sx={{ color: "#007767" }} /> : assistenciaDuplicada && <Tab label={constantes.Comentarios} sx={{ color: "#007767" }} />}
                    {values.Assistencia?.Cliente && values.Assistencia?.Id ? <Tab label={constantes.AssistenciaTecnica} sx={{ color: "#007767" }} /> : assistenciaDuplicada && <Tab label={constantes.AssistenciaTecnica} sx={{ color: "#007767" }} />}
                    {values.Assistencia?.Cliente && values.Assistencia?.Solucao && <Tab label={constantes.Solucao} sx={{ color: "#007767" }} />}
                  </Tabs>
                </Grid>
              </Grid>
            </Drawer>
          </Box>
        )}
      </Box>
      <Divider
        color="#0d9b86"
        sx={{ marginBottom: 2, marginLeft: 3, marginRight: 3 }}
      />
      {loading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
          margin={2}
          width={"auto"}
          flexDirection={"column"}
        >
          <Spinner
            animation="border"
            style={{ color: "#009180" }}
            role="status"
          />
          <span style={{ fontSize: 18, color: "#009180", padding: "5px 0" }}>
            {constantes.Carregando}...
          </span>
        </Box>
      ) : (
        <Box mb={10}>
          <AssistenciaTabs />
          <AssistenciaValidacoes />
          <AssistenciaListaAcoes />
        </Box>
      )}
    </>
  );
}
