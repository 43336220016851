import http from "../config/axiosConfig/http-common";
import { GetAtributos } from "../config/localstorageConfig/localstorage";
import ServiceBase from "./service.base";

class OrcamentoVendaService extends ServiceBase {

  getAll(filter) {
    return http.get("/orcamentovenda" + this.getFilter(filter));
  }

  get(id) {
    return http.get(`/orcamentovenda/${id}`);
  }

  post(data) {
    return http.post("/orcamentovenda", data);
  }

  put(id, data) {
    return http.put(`/orcamentovenda/${id}`, data);
  }

  delete(id) {
    return http.delete(`/orcamentovenda/${id}`);
  }

  new(data) {

    const orcamentocalculo = data;

    orcamentocalculo.Atributos?.map((item, i) => {
      item.ValorAtributo = { Id: Number(item.ValorAtributoId) };
    })

    orcamentocalculo.IndicadorIe = Number(orcamentocalculo.IndicadorIe);


    return http.post("/orcamentovenda/NewWeb", orcamentocalculo);
  }

  trasform(data) {
    return http.post("/orcamentovenda/Transform", data);
  }

  CalcularVencimentosValor(data) {
    return http.post("/orcamentovenda/CalcularVencimentosValor", data);
  }

  CalcularVencimentosPercentual(data) {
    return http.post("/orcamentovenda/CalcularVencimentosPercentual", data);
  }

  AtualizarSituacao(data) {
    return http.post("/orcamentovenda/AtualizarSituacao", data);
  }


  DuplicarOrcamento(id) {
    return http.post(`/orcamentovenda/${id}/Duplicar`);
  }

  GetListaRelatoriosCustomizados(id) {
    return http.get(`/orcamentovenda/${id}/ListagemImpressoes`);
  }

  ImprimirRelatorio(link) {
    return http.get(link,
      {
        responseType: "blob",
      });
  }

  GerarPedido(id) {
    return http.post(`/orcamentovenda/${id}/GerarPedido`);
  }

  Calcular(data) {

    const orcamentocalculo = data;

    /*
    const vencimentos = orcamentocalculo.Vencimentos.map((item, i) => {
      return {
        Dias: item.Dias,
        Data: item.Data,
        Valor: Number(item.Valor),
        Percentual: Number(item.Percentual),
        PercentualFixado: item.PercentualFixado,
        DataFixada: item.DataFixada,
        ValorFixado: item.ValorFixado,
        PercentualDigitado: Number(item.PercentualDigitado),
        ValorDigitado: Number(item.ValorDigitado)
      };
    })
    */

    orcamentocalculo.IndicadorIe = Number(orcamentocalculo.IndicadorIe);

    orcamentocalculo.Atributos?.map((item, i) => {
      item.ValorAtributo = { Id: Number(item.ValorAtributoId) };
    })

    const atributos_config = GetAtributos();

    orcamentocalculo.Atributos.forEach((item, index) => {

      if (item.ValorAtributo?.Id > 0) {

        const valor_item = atributos_config?.find((item_valor_atributo) => {
          return item_valor_atributo.Atributo.Id === item.Atributo.Id;
        })

        const existe = valor_item?.Valores?.find((valor_atributo) => {
          return valor_atributo.Id === item.ValorAtributo?.Id
        })

        if (!existe) {
          item.ValorAtributoId = null;
          item.Valor = null;
        }
      }
    })

    orcamentocalculo.TabelaPreco = orcamentocalculo.TabelaPreco?.Id === "" ? null : orcamentocalculo.TabelaPreco; 

    //orcamentocalculo.Vencimentos = vencimentos;

    orcamentocalculo.PercentualDesconto1 = orcamentocalculo.PercentualDesconto1 ? orcamentocalculo.PercentualDesconto1 : 0;
    orcamentocalculo.PercentualDesconto2 = orcamentocalculo.PercentualDesconto2 ? orcamentocalculo.PercentualDesconto2 : 0;
    orcamentocalculo.PercentualDesconto3 = orcamentocalculo.PercentualDesconto3 ? orcamentocalculo.PercentualDesconto3 : 0;
    orcamentocalculo.PercentualDesconto4 = orcamentocalculo.PercentualDesconto4 ? orcamentocalculo.PercentualDesconto4 : 0;
    orcamentocalculo.PercentualDesconto5 = orcamentocalculo.PercentualDesconto5 ? orcamentocalculo.PercentualDesconto5 : 0;
    orcamentocalculo.PercentualAcrescimo = orcamentocalculo.PercentualAcrescimo ? orcamentocalculo.PercentualAcrescimo : 0;
    orcamentocalculo.PercentualAcrescimoEspecial = orcamentocalculo.PercentualAcrescimoEspecial ? orcamentocalculo.PercentualAcrescimoEspecial : 0;
    orcamentocalculo.PercentualDescontoEspecial = orcamentocalculo.PercentualDescontoEspecial ? orcamentocalculo.PercentualDescontoEspecial : 0;

    return http.post("/orcamentovenda/Calculo", data);
  }

  CalcularItens(data) {

    const orcamentocalculo = data;

    orcamentocalculo.IndicadorIe = Number(orcamentocalculo.IndicadorIe);

    orcamentocalculo.Atributos?.map((item, i) => {
      item.ValorAtributo = { Id: Number(item.ValorAtributoId) };
    })

    orcamentocalculo.PercentualDesconto1 = orcamentocalculo.PercentualDesconto1 ? orcamentocalculo.PercentualDesconto1 : 0;
    orcamentocalculo.PercentualDesconto2 = orcamentocalculo.PercentualDesconto2 ? orcamentocalculo.PercentualDesconto2 : 0;
    orcamentocalculo.PercentualDesconto3 = orcamentocalculo.PercentualDesconto3 ? orcamentocalculo.PercentualDesconto3 : 0;
    orcamentocalculo.PercentualDesconto4 = orcamentocalculo.PercentualDesconto4 ? orcamentocalculo.PercentualDesconto4 : 0;
    orcamentocalculo.PercentualDesconto5 = orcamentocalculo.PercentualDesconto5 ? orcamentocalculo.PercentualDesconto5 : 0;
    orcamentocalculo.PercentualAcrescimo = orcamentocalculo.PercentualAcrescimo ? orcamentocalculo.PercentualAcrescimo : 0;
    orcamentocalculo.PercentualAcrescimoEspecial = orcamentocalculo.PercentualAcrescimoEspecial ? orcamentocalculo.PercentualAcrescimoEspecial : 0;
    orcamentocalculo.PercentualDescontoEspecial = orcamentocalculo.PercentualDescontoEspecial ? orcamentocalculo.PercentualDescontoEspecial : 0;

    return http.post("/orcamentovenda/CalculoPrecoItem", data);
  }


  ValidarItens(data) {
    return http.post("/orcamentovenda/Validar", data);
  }

  Cancelar(id, data) {
    return http.post(`/orcamentovenda/${id}/Cancelar`, data);
  }

}

export default new OrcamentoVendaService();