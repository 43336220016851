import { useContext, useState } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import SearchComp from "../../common/searchcomp";
import ListComp2 from "../../common/listcomp2";
import { Button, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import PedidoService from "../../services/service.pedido";
import { toast } from "react-toastify";
import { useFormikContext } from "formik";

const OrcamentoVendaImportarItensPedidoCtx = (props) => {

    const columns = [
        {
            field: 'Id',
            headerName: 'Número',
            width: 90,
            key: true
        },
        {
            field: 'DataCadastro',
            headerName: 'Cadastro',
            width: 90,
            type: 'date',
            format: 'dd/MM/yyyyy'
        },
        {
            field: 'DataFaturamento',
            headerName: 'Faturamento',
            width: 90,
            type: 'date',
            format: 'dd/MM/yyyy'
        },
        {
            field: 'Situacao',
            headerName: 'Situacao',
            width: 150,
            // type: "custom",
            // onGetCustom: (item) => {
            //     const style = getStyle(item);
            //     return (
            //         <Button style={style}>{item}</Button>
            //     )
            // }
        },
        {
            field: 'ClienteId',
            headerName: 'Cod Cliente',
            width: 150,
        },
        {
            field: 'ClienteNome',
            headerName: 'Nome Cliente',
            type: "text",
            width: 150,
        },
        {
            field: 'ValorPedido',
            headerName: 'Valor Pedido',
            width: 150,
            digits: 2,
        }
    ];

    const columnsFilter = [
        {
            field: 'Id',
            headerName: 'Numero',
            tipo: 'number'
        },
        {
            field: 'ClienteId',
            headerName: 'Cod Cliente',
            tipo: 'number'
        },
        {
            field: 'ClienteNome',
            headerName: 'Nome Cliente',
            tipo: "text",
            width: 150,
        },


    ]

    const [pedidos, setPedidos] = useState();

    const [filter, setFilter] = useState();
    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1);

    function getStyle(Situacao) {
        if (Situacao === "Rascunho") {
            return { background: "#FFFFB0", color: 'black' }
        } else if (Situacao === "Bloqueado") {
            return { background: "#B1D8D8", color: 'black' }
        } else if (Situacao === "Liberado") {
            return { background: "#fff", color: 'black' }
        } else if (Situacao === "Finalizado") {
            return { background: "#98C4F3", color: 'black' }
        } else if (Situacao === "Cancelado") {
            return { background: "#FFBFBF", color: 'black' }
        }
        return null
    }

    const {
        constantes,
        modalImportarItensPedido,
        setModalImportarItensPedido,
        updateItensImportados,
        setImportandoItens,
        handleError
    } = useContext(OrcamentoVendaContext);


    const {
        setFieldValue,
        values
    } = useFormikContext();

    async function getPedido(id) {
        setModalImportarItensPedido(false);
        setImportandoItens(true);
        await PedidoService.get(id)
            .then((response) => {
                const itensOrcamento = response.data.Itens.map((item) => {
                    return {
                        CodProduto: item.Produto.Id,
                        Quantidade: item.Quantidade,
                        IdentificacaoProjeto: item.IdentificacaoProjeto,
                        Atributos: item.Atributos
                            ? item.Atributos.map((item_atributo) => {
                                return {
                                    Atributo: item_atributo.Atributo 
                                      ? {
                                        ...item_atributo.Atributo,
                                        Grupo : item_atributo.Grupo
                                      }
                                      : null,
                                    Grupo: item_atributo.Grupo,
                                    Valor: item_atributo.Valor,
                                    ValorAtributo: item_atributo.ValorAtributo
                                }
                            })
                            : null
                        ,
                        // UnidadeMedidaComercial: item.UnidadeMedidaComercial
                    }
                })
                updateItensImportados(itensOrcamento, setFieldValue, values);
            })
            .catch((error) => {
                handleError(error);
            })
    }

    const onToogle = () => {
        setModalImportarItensPedido(!modalImportarItensPedido);
    }

    function pesquisar(values) {
        const filter = {
            situacao: null,
            campo: values.searchField,
            operacao: values.searchoperator,
            valor: values.searchValue,
            page: values.page
            // campoorder: orderField.current,
            // tipoorder: ordertype.current
        };
        setFilter(filter);
        PedidoService.ListVendedor(filter)
            .then((response) => {
                setPedidos(response.data.value);
                setPages(response.data.pages);
            })
            .catch(error => {
                toast.error(error.response.data.error.message);

            });
    }

    function onPageSelect(number) {
        pesquisar({
            ...filter,
            page: number
        });
    }

    function setItemSelected(item) {
        getPedido(item.Id);
    }

    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalImportarItensPedido}
                onHide={onToogle}
            >
                <ModalHeader toggle={onToogle} closeButton></ModalHeader>
                <ModalBody>
                    <SearchComp
                        title={constantes.ImportarItensDoPedido}
                        campos={columnsFilter}
                        onSearch={(values) => {
                            pesquisar(values);
                        }}
                    />

                    <ListComp2
                        data={pedidos}
                        msgAviso={false}
                        selecionar
                        pages={pages}
                        columns={columns}
                        onPageSelect={(number) => onPageSelect(number)}
                        onItemSelected={(item) => setItemSelected(item)}
                    />
                </ModalBody>
            </Modal>
        </>
    )
}


export default OrcamentoVendaImportarItensPedidoCtx;
