import http from "../config/axiosConfig/http-common";
import ServiceBase from "./service.base";

class TipoAssistencia extends ServiceBase {
    
    getAll(filter) {
      return http.get("/TiposAssistencia" + this.getFilter(filter) );
    }
    getId(id) {
      return http.get(`/TiposAssistencia/${id}`);
    }     
}

export default new TipoAssistencia


