import { Accordion, Alert, Container, FormLabel, ProgressBar, Stack } from "react-bootstrap";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import OrcamentoVendaDescontosCtx from "./orcamentovenda-descontos";
import OrcamentoVendaIdentificacaoCtx from "./orcamentovenda-identificacao";
import OrcamentoVendaAtributosCtx from "./orcamentovenda-atributos";
import OrcamentoVendaCondicoesCtx from "./orcamentovenda-condicoes";
import OrcamentoVendaFreteCtx from "./orcamentovenda-frete";
import OrcamentoVendaItensFinameCtx from "./orcamentovenda-itensfiname";
import OrcamentoVendaItensCtx from "./orcamentovenda-itens";
import OrcamentoVendaTotaisCtx from "./orcamentovenda-totais";
import OrcamentoVendaObservacoesCtx from "./orcamentovenda-observacoes";
import OrcamentoVendaEntregaCtx from "./orcamentovenda-entrega";
import OrcamentoVendaSalvarCtx from "./orcamentovenda-salvar";
import OrcamentoVendasValidacoesCtx from "./orcamentovenda-validacoes";
import { useContext, useEffect } from "react";
import { useFormikContext } from "formik";

const OrcamentoVenda = () => {

  const {
    orcamentoVenda,
    orcamentoEdicao,
    orcamentoDuplicado,
    constantes,
    exigirItensFiname,
    isLoading,
    loadingError,
    somenteLeitura,
    configs,
    updateOrcamentoVencido,
    orcamentoVencido,
    setOrcamentoVencido
  } = useContext(OrcamentoVendaContext)

  const {
    values,
    setFieldValue
  } = useFormikContext();


  useEffect(() => {
    orcamentoEdicao && setFieldValue("OrcamentoVenda", orcamentoEdicao);

    if ((orcamentoEdicao) && (!somenteLeitura)) {
      const today = new Date();
      setOrcamentoVencido(orcamentoEdicao.DataValidade < today.toISOString().slice(0, 10));
    }

  }, [orcamentoEdicao])

  useEffect(() => {
    orcamentoDuplicado && setFieldValue("OrcamentoVenda", orcamentoDuplicado);
  }, [orcamentoDuplicado])

  useEffect(() => {
    orcamentoVencido && orcamentoEdicao && updateOrcamentoVencido(orcamentoEdicao, setFieldValue, values);
  }, [orcamentoVencido, orcamentoEdicao])


  return (
    <>
      <Stack gap={3}>
        <Container fluid className="d-flex align-items-center my-3">
          <h1 className="text-black-50">{constantes.OrcamentoDeVenda}</h1>
        </Container>
        {loadingError &&
          <Alert variant="danger">
            {loadingError.message}
          </Alert>}
        {isLoading &&
          <Container>
            <FormLabel>Carregando orçamento de Venda...</FormLabel>
            <ProgressBar animated now={70} />
          </Container>
        }
        {orcamentoVencido &&
          <Alert variant="danger">
            {constantes.Msgorcamentovencido}
          </Alert>}

        {!isLoading && !loadingError &&
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {constantes.Identificacao}
              </Accordion.Header>
              <Accordion.Body>
                <OrcamentoVendaIdentificacaoCtx />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        }
        {orcamentoVenda?.Municipio?.Id && <>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {constantes.Atributos}
              </Accordion.Header>
              <Accordion.Body>
                <OrcamentoVendaAtributosCtx />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {constantes.Condicoes}
              </Accordion.Header>
              <Accordion.Body>
                <OrcamentoVendaCondicoesCtx />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>


       

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {constantes.Frete}
              </Accordion.Header>
              <Accordion.Body>
                <OrcamentoVendaFreteCtx />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {exigirItensFiname &&
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {constantes.ItensFinameDoOrcamento}
                </Accordion.Header>
                <Accordion.Body>
                  <OrcamentoVendaItensFinameCtx />
                </Accordion.Body>
              </Accordion.Item >
            </Accordion >
          }

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {constantes.ItensDoOrcamento}
              </Accordion.Header>
              <Accordion.Body>
                <OrcamentoVendaItensCtx />
              </Accordion.Body>
            </Accordion.Item >
          </Accordion >

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {constantes.Totais}
              </Accordion.Header>
              <Accordion.Body>
                <OrcamentoVendaTotaisCtx />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {
            (configs.PermitirAlterarDescontosVenda ||
              configs.PermitirAlterarAcrescimoVenda ||
              orcamentoVenda?.CondicoesEspeciais.length > 0) &&
              !configs.DesabilitarCamposConformePerfil &&
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {constantes.DescontosAcrescimos}
                </Accordion.Header>
                <Accordion.Body>
                  <OrcamentoVendaDescontosCtx />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          }
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {constantes.Observacoes}
              </Accordion.Header>
              <Accordion.Body>
                <OrcamentoVendaObservacoesCtx />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion style={{ paddingBottom: 80 }} defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {constantes.EnderecoDaEntrega}
              </Accordion.Header>
              <Accordion.Body>
                <OrcamentoVendaEntregaCtx />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
        }
      </Stack >
      <OrcamentoVendasValidacoesCtx />
      <OrcamentoVendaSalvarCtx />
    </>
  )
}

export default OrcamentoVenda;