import { forwardRef } from "react";
import { ErrorMessage, useField, useFormikContext } from "formik";
import SearchBarControl from "./search-Bar.Control";
import { Badge } from "react-bootstrap";

const SearchBarControlFormik = forwardRef((props, ref) => {
  const {
    name,
    nameId,
    nameDesc,
    storagename
  } = props;

  const { handleChange } = useFormikContext();

  const [fieldId, metaId, handleId] = useField(nameId);
  const [fieldDesc, metaDesc, handleDEsc] = useField(nameDesc ?? " ");

  return (
    <>
      <SearchBarControl
        {...props}
        ref={ref}
        handleChange={handleChange}
        valueId={metaId.value ?? ""}
        valueDesc={metaDesc.value ?? ""}
        storagename={storagename}
        onModelNotFound={() => {
          props.onItemClear && props.onItemClear();
        }}
        onGetMessage={
          () => {
            return (
              <>
                <ErrorMessage name={nameId}>
                  {(msg) => (
                    <Badge bg="danger">{msg}</Badge>
                  )}
                </ErrorMessage>
                {name && <ErrorMessage name={name}>

                  {(msg) => (
                    <Badge bg="danger">{msg}</Badge>
                  )}
                </ErrorMessage>}
              </>
            )
          }
        }
      />
    </>
  );
});

export default SearchBarControlFormik;
