import { useContext, useState } from "react";
import { OrcamentoVendaContext } from "../../contexts/orcamentovenda-context";
import { Alert, Badge, Button, Col, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ErrorMessage, useFormikContext } from "formik";
import NewModalItemOrcamentoVendaControl from "../ui_comp/Modals/ModalItemOrcamentoVendaControl";
import ListComp2 from "../../common/listcomp2";
import OrcamentoModalImportaItensErros from "./orcamento-ModalImportaItensErros";
import { GoAlertFill } from 'react-icons/go';
import { BsEyeFill } from 'react-icons/bs'
import OrcamentoVendaImportarItensCtx from "./orcamentovenda-importaritens";
import OrcamentoVendaImportarItensPedidoCtx from "./orcamentovenda-importaritensPedido";

const OrcamentoVendaItensCtx = () => {

    const {
        orcamentoVenda,
        somenteLeitura,
        constantes,
        descontosTabela,
        updateItens,
        updateItensImportados,
        agruparItens,
        deleteGroup,
        handleToggleModalItem,
        deleteItem,
        modalItem,
        setErrosModalErrorsImportarItens,
        avisoImportarItens,
        listagemValidacao,
        setModalItem,
        modalImportarItens,
        setModalImportarItens,
        setModalImportarItensPedido,
        importandoItens,
        indexItemSelected,
        setIndexItemSelected
    } = useContext(OrcamentoVendaContext);

    const {
        values,
        errors,
        setFieldValue,
        validateOnMount
    } = useFormikContext();


    const [continueAddItem, setContinueAddItem] = useState(false);

    const ListaItens = () => {
        const columnsItens = [
            {
                field: "Item",
                headerName: "Item",
                width: 40,
                key: true,
            },
            {
                field: "CodProduto",
                headerName: "Cód Produto",
                width: 40,
            },
            {
                field: "Referencia",
                headerName: "Referência",
                width: 40,
            },
            {
                field: "NomeProdutoTraduzido",
                headerName: "Nome Produto",
                width: 40,
            },
            {
                field: "UnidadeMedidaTraduzido",
                headerName: "Und",
                width: 40,
            },
            {
                field: "PrecoTabela",
                headerName: "Preço Tabela",
                width: 40,
                digits: 4,
                align: "right",
            },
            {
                field: "PrecoTabelaComDesconto",
                headerName: "Preço Tabela com Desconto",
                width: 40,
                digits: 4,
                align: "right",
            },
            {
                field: "PrecoUnitario",
                headerName: "Preço Unitário",
                width: 40,
                digits: 4,
                align: "right",
            },
            {
                field: "Quantidade",
                headerName: "Quantidade",
                width: 40,
                digits: 4,
                align: "right",
            },
            {
                field: "TotalItem",
                headerName: "Total Item",
                width: 40,
                digits: 2,
                align: "right",
            },
        ];

        const btns = [
            {
                getBtn: (itemdata, index) => {
                    return (
                        <Button
                            value={index}
                            onClick={(arg) => {
                                setIndexItemSelected(index);
                                setContinueAddItem(false);
                                setModalItem(true);
                            }}  >
                            <FontAwesomeIcon value={index} icon={faEdit}>

                            </FontAwesomeIcon>
                        </Button>

                    )
                },
            },
            {

                getBtn: (itemdata, index) => {
                    return (
                        <Button
                            variant="danger"
                            value={index}
                            onClick={(arg) => {
                                deleteItem(index, setFieldValue, values);
                            }}  >
                            <FontAwesomeIcon value={index} icon={faTrash}>

                            </FontAwesomeIcon>
                        </Button>

                    )
                },
            },
        ];

        const btns_leitura = [
            {
                text: "View",
                onClick: (arg) => {
                    setContinueAddItem(false);
                    setIndexItemSelected(parseInt(arg.target.value));
                    setModalItem(true);
                },
            },
        ];

        return (
            <>
                <Row>
                    <ListComp2
                        data={agruparItens(orcamentoVenda.Itens)}
                        striped={false}
                        msgAviso={false}
                        columns={columnsItens}
                        buttons={somenteLeitura ? btns_leitura : btns}
                        onGetStyle={(itemData) => {
                            if ((itemData?.SituacaoProduto === "Inativo") || (itemData?.SituacaoProduto === "Bloqueado")) {
                                return { background: "crimson", color: 'white' }
                            }
                            if (itemData?.PrecoTabela === 0) {
                                return { background: "#fff0b5", color: "black" }
                            }
                            if (itemData?.PrecoVenda === 0) {
                                return { background: "#fff0b5", color: "black" }
                            }

                            return null
                        }}
                        onGetBtnGroup={(item, index) => {
                            return (
                                !somenteLeitura ?
                                    <Button
                                        variant="danger"
                                        onClick={() => {
                                            deleteGroup(item.group, setFieldValue, values)
                                        }
                                        }>
                                        <FontAwesomeIcon value={index} icon={faTrash}>

                                        </FontAwesomeIcon>
                                    </Button>
                                    : null
                            )
                        }}
                    />
                </Row>
            </>
        );
    };

    const BotoesItens = (props) => {
        const handleModalErrors = () => {
            setErrosModalErrorsImportarItens(true)
        }

        return (
            <>
                {listagemValidacao.length !== 0 ?
                    (<Alert style={{ display: "flex", alignItems: "center", background: "#fcf8e3", border: "1px solid #f9ca90", gap: 10 }}>
                        <GoAlertFill color="#e09731" size={25} style={{ marginTop: -2 }} /><p style={{ marginTop: 15, color: "#603905" }}>{constantes.MsgPendenciasImportarItensErros}</p>
                        <Button style={{ background: "#e09731", border: "none", fontSize: 15 }} onClick={() => { handleModalErrors() }}> <BsEyeFill size={18} style={{ marginTop: -3 }} /> {constantes.Visualizar}</Button>
                    </Alert>) : ""}
                {orcamentoVenda.TabelaPreco &&
                <Row>
                    <Col>
                        <Button
                            disabled={props.disabled}
                            variant="outline-secondary"
                            onClick={() => {
                                setIndexItemSelected(null);
                                setContinueAddItem(false);
                                setModalItem(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faAdd} />
                            {constantes.Incluir}...
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            disabled={props.disabled}
                            variant="outline-secondary"
                            onClick={() => {
                                setIndexItemSelected(null);
                                setContinueAddItem(true);
                                setModalItem(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faAdd} />
                            {constantes.IncluirVarios}...
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            disabled={props.disabled}
                            variant="outline-secondary"
                            onClick={() => {
                                setModalImportarItens(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faAdd} />
                            {constantes.ImportarItens}...
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            disabled={props.disabled}
                            variant="outline-secondary"
                            onClick={() => {
                                setModalImportarItensPedido(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faAdd} />
                            {constantes.ImportarItensPedidos}...
                        </Button>
                    </Col>
                </Row>}

            </>
        );
    };

    return (
        <>
            {!somenteLeitura && values.OrcamentoVenda?.Municipio?.Id && (
                <BotoesItens
                    disabled={
                        values.OrcamentoVenda?.ExigirItensFiname &&
                        values.OrcamentoVenda?.ItensFiname.length === 0
                    }
                />
            )}

            <ListaItens
                disabled={somenteLeitura}
            />
            {validateOnMount && errors && errors.Validacoes && <Badge bg="danger">{errors.Validacoes}</Badge>}

            {!validateOnMount && <ErrorMessage name={'Validacoes'}>
                {(msg) => <Badge bg="danger">{msg}</Badge>}
            </ErrorMessage>}

            {importandoItens &&
                <Alert variant="light" >
                    {constantes.AguardeImportandoItens} ...
                    <Spinner animation="border" variant="primary" />
                    <Spinner animation="border" variant="secondary" />
                    <Spinner animation="border" variant="success" />
                    <Spinner animation="border" variant="danger" />
                    <Spinner animation="border" variant="warning" />
                    <Spinner animation="border" variant="info" />
                    <Spinner animation="border" variant="light" />
                    <Spinner animation="border" variant="dark" />
                </Alert>
            }
            <OrcamentoVendaImportarItensCtx />
            <OrcamentoVendaImportarItensPedidoCtx />

            <NewModalItemOrcamentoVendaControl
                somenteLeitura={somenteLeitura}
                maioritem={orcamentoVenda.Itens?.length}
                selectedItem={Number(indexItemSelected ?? -1) >= 0 ? orcamentoVenda.Itens[indexItemSelected] : null}
                indexselectedItem={indexItemSelected}
                ItensFiname={orcamentoVenda.ItensFiname}
                TabelaPrecoId={orcamentoVenda.TabelaPreco?.Id}
                orcamentovenda={orcamentoVenda}
                descontosTabela={descontosTabela}
                cfop={orcamentoVenda.Cfop}
                toggle={() => {
                    handleToggleModalItem()
                }}

                modal={modalItem}

                configs={orcamentoVenda.Configs}
                continuar={continueAddItem}
                onAddItem={(item) => {
                    updateItens(item, -1, setFieldValue, values);
                    if (!continueAddItem) {
                        setModalItem(false);
                    }
                }}
                onEditItem={(item) => {
                    updateItens(item, indexItemSelected, setFieldValue, values);
                    if (!continueAddItem) {
                        setModalItem(false);
                    }
                }}
            />
            <OrcamentoModalImportaItensErros />
        </>
    )

}

export default OrcamentoVendaItensCtx;
